.slick-slider {
    box-sizing: border-box;
    display: block;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    touch-action: pan-y;
    -webkit-touch-callout: none;
    user-select: none;
}

.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;

    &::before,
    &::after {
        content: '';
        display: table;
    }

    &::after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    [dir='rtl'] & {
        float: right;
    }

    img {
        &:not(.product-tile__image-shadow):not(.product-tile__bg):not(.product-tile__image-small-shadow) {
            display: block;
        }
    }

    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        border: 1px solid transparent;
        display: block;
        height: auto;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}

.slider {
    &__custom-arrows {
        .arrow {
            &__container {
                border: rem-calc(1) solid $grey3;
                cursor: pointer;
                display: none;
                height: rem-calc(35);
                position: absolute;
                top: 50%;
                width: rem-calc(35);
                z-index: 1;

                &--prev {
                    left: rem-calc(-70);
                    transform: rotate(45deg) translateY(-50%);
                }

                &--next {
                    right: rem-calc(-70);
                    transform: rotate(-45deg) translateY(-50%) scale(-1, 1);
                }

                @include media-breakpoint-up(lg) {
                    display: block;
                }

                @include media-breakpoint-up(xl) {
                    height: rem-calc(48);
                    width: rem-calc(48);
                }
            }

            &__diamond {
                background: $light-white12;
                border: rem-calc(1) solid $light-white12;
                display: block;
                height: 100%;
                position: absolute;
                transform: scale(0);
                width: 100%;
            }
        }

        .slick-prev,
        .slick-next {
            background-image: url('../images/arrow-slider-rotated.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            height: rem-calc(18);
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: rem-calc(18);
            z-index: 1;

            &::before {
                display: none;
            }

            &:hover,
            &:focus {
                background-image: url('../images/arrow-slider-rotated.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }

            @include media-breakpoint-up(xl) {
                height: rem-calc(24);
                width: rem-calc(24);
            }
        }
    }
}

.slider-container {
    .slick-list {
        padding: 0 rem-calc(52);
    }

    .slick-slide {
        height: 45vw;
        padding: 0 rem-calc(7);
    }

    .slick-dots {
        padding-top: rem-calc(22);
        position: static;

        li {
            height: auto;
            margin: 0;
            padding: rem-calc(7.5);
            width: auto;

            button {
                background-color: $white;
                border: rem-calc(1) solid $accent;
                border-radius: 50%;
                height: rem-calc(15);
                padding: 0;
                width: rem-calc(15);

                &::before {
                    display: none;
                }
            }

            &.slick-active {
                button {
                    background-color: $accent;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        @include make-container();

        max-width: map-get($container-max-widths, lg);
    }

    @include media-breakpoint-up(xl) {
        max-width: map-get($container-max-widths, xl);
    }

    @include media-breakpoint-up(xxl) {
        max-width: map-get($container-max-widths, xxl);
    }
}
