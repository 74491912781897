.social-links {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;

    &__item {
        + .social-links__item {
            margin-left: rem-calc(30);
        }
    }

    &__img {
        max-width: rem-calc(24);
    }
}
