body {
    color: $alto;
    font-family: $font-sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 300;
}

h1 {
    font-family: $font-serif;
    font-size: rem-calc(34);
    font-weight: 400;
    line-height: 1.35;

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(50);
        line-height: 1.5;
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(64);
    }
}

h2 {
    font-family: $font-sans-serif;
    font-size: rem-calc(18);
    font-weight: 400;
    line-height: 1.35;

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(30);
        line-height: 1.5;
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(36);
    }
}

h3 {
    font-family: $font-sans-serif;
    font-size: rem-calc(16);
    font-weight: 400;
    line-height: 1.35;

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(24);
        line-height: 1.5;
    }
}

h4,
h5,
h6 {
    color: $primary;
    font-family: $font-sans-serif;
    font-size: rem-calc(16);
    font-weight: 400;
    line-height: rem-calc(24);
}

p {
    color: $secondary;
    font-family: $font-sans-serif;
    font-size: rem-calc(20);
    font-weight: 300;
    line-height: rem-calc(28);

    a {
        font-size: rem-calc(20);
        line-height: rem-calc(28);
    }
}

a {
    color: $link-color;
    font-family: $font-sans-serif;
    font-weight: 300;
    position: relative;
    text-decoration: none;

    &:hover {
        color: $link-hover-color;
        text-decoration: none;
    }
}
