$button-colors: (
    'primary':    $accent,
    'secondary':  $primary,
    'success':    $success,
    'info':       $info,
    'warning':    $warning,
    'danger':     $danger,
    'light':      $white-rock,
    'dark':       $woodsmoke
);

@each $color, $value in $button-colors {
    .btn-#{$color} {
        @include button($value);
    }

    .btn-square-#{$color} {
        @include button($value, $square: true);
    }
}

@each $color, $value in $button-colors {
    .btn-outline-#{$color} {
        @include button-outline($value);
    }

    .btn-square-outline-#{$color} {
        @include button-outline($value, $square: true);
    }
}

.btn-link {
    @include button-link();
}

.btn-fancy-link {
    @include button-fancy-link();
}
