%sidemenu-padding {
    padding-left: rem-calc(27);
    padding-right: rem-calc(27);

    @include media-breakpoint-up(md) {
        padding-left: rem-calc(60);
        padding-right: rem-calc(60);
    }

    @include media-breakpoint-up(xl) {
        padding-left: rem-calc(98);
        padding-right: rem-calc(64);
    }
}

$ns: 'side-menu';

.#{$ns} {
    background: $primary;
    height: 100vh;
    opacity: 0;
    position: fixed;
    right: -100%;
    top: 0;
    transition: all 0.85s;
    width: 100%;
    z-index: 99;

    .arrow-menu {
        &::before {
            border: solid $white;
            border-width: rem-calc(0 1 1 0);
            content: '';
            display: inline-block;
            padding: rem-calc(5);
            position: absolute;
            right: rem-calc(33);
            top: 50%;
            transform: translate(0, -50%) rotate(-45deg);
            transition: transform 0.3s ease-out;

            @include media-breakpoint-up(lg) {
                border-width: rem-calc(0 2 2 0);
                padding: rem-calc(7);
                right: rem-calc(75);
            }

            @include media-breakpoint-up(xl) {
                right: rem-calc(67);
            }
        }
    }

    > .container {
        max-width: none;
        padding: 0;
    }

    &.show {
        opacity: 1;
        right: 0;

        .side-menu__highlights {
            animation: right-animate 1.5s ease forwards;
        }
    }

    &.closed {
        .side-menu__highlights {
            animation: right-close-animate 1.5s ease forwards;
        }
    }

    .toggle-menu {
        cursor: pointer;
    }

    &__logo {
        align-items: center;
        display: flex;

        .typo-logo {
            height: rem-calc(30);

            @include media-breakpoint-up(md) {
                height: rem-calc(47);
            }
        }
    }

    &__navigation {
        background-color: $sidebar-bg;
        height: 100vh;
        overflow-y: scroll;
        padding-top: rem-calc(28);

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: column;
            height: auto;
            justify-content: space-between;
            min-height: 100vh;
            overflow: hidden;
            padding-top: rem-calc(44);

            > div {
                flex: 1 1;

                &.menu-group,
                &.#{$ns}__header {
                    flex: 0;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            height: 100vh;
            overflow-y: scroll;
        }
    }

    &__map {
        list-style: none;
        margin: 0;
        padding-bottom: rem-calc(38);
        padding-top: rem-calc(38);
        position: relative;

        &--item {
            &-link {
                @extend %sidemenu-padding;

                color: $white;
                cursor: pointer;
                display: block;
                font-family: $font-sans-serif;
                font-size: rem-calc(22);
                font-weight: 300;
                letter-spacing: rem-calc(1.69);
                line-height: rem-calc(33);
                padding-bottom: rem-calc(9);
                padding-top: rem-calc(10);
                position: relative;
                text-decoration: none;
                text-transform: uppercase;
                transition: background 0.3s ease-out, opacity 0.3s ease-out;

                &:hover {
                    background-color: adjust-color($sundance, $alpha: -0.75);
                    color: $white;
                    font-weight: 500;
                    opacity: 1;
                    text-decoration: none;
                }

                @include media-breakpoint-up(xl) {
                    opacity: 0.7;
                }
            }

            &.show {
                .#{$ns}__map--item-link {
                    background-color: adjust-color($sundance, $alpha: -0.75);
                    color: $white;
                    font-weight: 500;
                    opacity: 1;
                }

                .arrow-menu {
                    &::before {
                        transform: translate(0, -50%) rotate(45deg);
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: rem-calc(50);
            padding-top: rem-calc(50);
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: rem-calc(84);
            padding-top: rem-calc(86);
        }
    }

    &__account {
        border-bottom: rem-calc(1) dashed adjust-color($sundance, $alpha: -0.78);
        border-top: rem-calc(1) dashed adjust-color($sundance, $alpha: -0.78);
        list-style: none;
        padding: rem-calc(39 0 52);
        position: relative;

        &--item {
            &-link {
                @extend %sidemenu-padding;

                color: $white;
                display: block;
                font-family: $font-sans-serif;
                font-size: rem-calc(18);
                font-weight: 300;
                letter-spacing: rem-calc(1.38);
                line-height: rem-calc(27);
                padding-bottom: rem-calc(12);
                padding-top: rem-calc(12);
                text-transform: uppercase;
                transition: background 0.3s ease-out, opacity 0.3s ease-out;

                &::after {
                    content: none;
                }

                &:hover {
                    background-color: adjust-color($sundance, $alpha: -0.75);
                    color: $white;
                    font-weight: 500;
                    opacity: 1;
                }

                @include media-breakpoint-up(xl) {
                    opacity: 0.7;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: rem-calc(44);
            padding-top: rem-calc(35);
        }
    }

    &__info {
        @extend %sidemenu-padding;

        padding-top: rem-calc(34);
        position: relative;

        address {
            margin-bottom: rem-calc(30);
            text-align: center;

            @include media-breakpoint-up(xl) {
                text-align: left;
            }
        }

        dl {
            margin-bottom: 0;
        }

        dt {
            @include heading($sm-font-size: 31, $sm-line-height: 1.2, $color: $white, $multiple-size: false);

            margin: 0 auto 0.51em;
            max-width: rem-calc(180);

            @include media-breakpoint-up(xl) {
                font-size: rem-calc(24);
                line-height: 1;
                margin-bottom: 1em;
                max-width: none;
                text-align: left;
            }
        }

        dd {
            @include description($sm-font-size: 14, $sm-line-height: 1.5, $color: $white, $multiple-size: false);

            margin: 0;

            a {
                color: inherit;
                font: inherit;
                line-height: inherit;

                &:hover {
                    text-decoration: underline;
                }
            }

            &.phone {
                @include media-breakpoint-down(lg) {
                    font-size: rem-calc(18);
                    margin-top: rem-calc(32);
                    opacity: 0.56;
                }
            }

            @include media-breakpoint-up(xl) {
                font-size: rem-calc(18);
                margin-bottom: 0.77em;
                opacity: 0.56;
            }
        }

        &-links {
            display: flex;
            justify-content: center;

            a {
                @include description($sm-font-size: 14, $sm-line-height: 1.14, $color: $white, $multiple-size: false);

                font-weight: 500;
                letter-spacing: rem-calc(2);
                margin-bottom: 0;
                text-decoration: underline;
                text-transform: uppercase;

                + a {
                    &::before {
                        content: '|';
                        letter-spacing: normal;
                        margin: 0 rem-calc(10);
                        text-decoration: none;
                    }
                }

                &:hover {
                    color: $white;
                    text-decoration: underline;
                }
            }

            @include media-breakpoint-up(xl) {
                justify-content: flex-start;
            }
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: rem-calc(172);
            padding-top: rem-calc(130);
        }
    }

    &__footer {
        padding: rem-calc(17) 0;

        > .content-asset {
            align-items: center;
            display: flex;
            flex-direction: column-reverse;
            flex-wrap: wrap;
            justify-content: space-between;

            @include media-breakpoint-up(xl) {
                flex-direction: row;
            }
        }

        &-copy {
            @include description($sm-font-size: 14, $sm-line-height: 1.5, $color: $white, $multiple-size: false);

            border-top: rem-calc(1) solid adjust-color($sundance, $alpha: -0.59);
            letter-spacing: normal;
            margin: rem-calc(72 0 15);
            opacity: 0.75;
            padding: rem-calc(32 21 0);
            text-align: center;
            width: 100%;

            @include media-breakpoint-up(md) {
                padding-left: rem-calc(60);
                padding-right: rem-calc(60);
            }

            @include media-breakpoint-up(xl) {
                border: 0;
                font-size: rem-calc(16);
                margin-top: rem-calc(15);
                padding: 0;
                text-align: left;
                width: auto;
            }
        }

        &-social {
            display: flex;
            list-style: none;
            margin: rem-calc(15 -15);
            padding: 0;

            li {
                padding: 0 rem-calc(15);
            }

            a {
                color: $light-white2;
                display: block;
                font-size: rem-calc(26);
                line-height: 1;
                transition: opacity 0.3s ease-out;

                &:hover {
                    color: $light-white2;
                    opacity: 1;
                }

                @include media-breakpoint-up(xl) {
                    font-size: rem-calc(24);
                    opacity: 0.28;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            border-top: rem-calc(1) solid adjust-color($sundance, $alpha: -0.59);
            padding: rem-calc(10 64 13 98);
        }
    }

    &__highlights {
        overflow-y: scroll;

        @include media-breakpoint-up(xl) {
            height: 100vh;
            overflow: hidden;

            > .row {
                flex: 0 0 50%;
                max-height: 50%;
            }

            .content-asset {
                height: 100%;
                overflow: hidden;
            }
        }
    }

    &__highlight {
        height: 100%;
        position: relative;

        &-image {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;

            &::before,
            &::after {
                bottom: 0;
                content: '';
                display: block;
                left: 0;
                position: absolute;
                width: 100%;
            }

            &::before {
                background: $sidebar-bg;
                height: 100%;
                opacity: 0.37;
                transition: opacity 1.1s ease;
                z-index: 1;
            }

            &::after {
                background: linear-gradient(184.1deg, transparent 34.85%, rgba($sidebar-bg, 0.98) 97.39%, $sidebar-bg 97.39%);
                background-position: bottom center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                height: rem-calc(275);
                transition: background 1.1s ease;
                z-index: 2;
            }

            img {
                height: 100%;
                object-fit: cover;
                transform: scale(1);
                transition: transform 1.1s ease;
                width: 100%;
            }
        }

        &-link {
            &:hover {
                .side-menu__highlight-image {
                    &::before {
                        opacity: 0.15;
                    }

                    &::after {
                        background-size: 100% 125%;
                    }

                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: flex-end;
            padding: rem-calc(38 48);
            position: relative;
            width: 100%;
            z-index: 1;
        }

        &-eyebrow {
            @include description($sm-font-size: 16, $sm-line-height: 1.5, $color: $white, $multiple-size: false);

            font-weight: 400;
            letter-spacing: rem-calc(2);
            margin-bottom: 0;
            text-transform: uppercase;
        }

        &-heading {
            @include heading($sm-font-size: 34, $sm-line-height: 1.23, $color: $white, $multiple-size: false);

            font-weight: 400;
            letter-spacing: rem-calc(2);
            margin-bottom: 0;

            @include media-breakpoint-up(xxl) {
                max-width: rem-calc(410);
            }
        }
    }

    &__header {
        @extend %sidemenu-padding;

        align-items: center;
        display: flex;
        justify-content: space-between;

        .toggle-menu {
            color: $white;
            font-size: rem-calc(26);
            opacity: 1;
            transition: opacity 0.3s ease;

            &:hover {
                opacity: 0.75;
            }

            @include media-breakpoint-up(xl) {
                font-size: rem-calc(55);
            }
        }
    }

    .dropdown-menu {
        background-color: adjust-color($sundance, $alpha: -0.8);
        border: 0;
        border-radius: 0;
        display: block;
        float: none;
        margin: 0;
        padding: 0 0 rem-calc(16);

        &__container {
            height: 0;
            overflow: hidden;
            transition: height 0.3s ease-out;
        }

        .dropdown-item {
            padding: 0;

            &.active,
            &:active,
            &:hover,
            &:focus {
                background: transparent;
            }
        }

        .dropdown-link {
            @extend %sidemenu-padding;

            color: $white;
            cursor: pointer;
            display: block;
            font-size: rem-calc(22);
            line-height: rem-calc(33);
            padding-bottom: rem-calc(9);
            padding-top: rem-calc(10);
            transition: opacity 0.3s ease-out;

            &:hover {
                background-color: transparent;
                color: $white;
                font-weight: 500;
                opacity: 1;
            }

            @include media-breakpoint-up(xl) {
                opacity: 0.7;
            }
        }
    }

    .dropdown-toggle {
        &::after {
            display: none;
        }
    }

    @include media-breakpoint-up(lg) {
        overflow: scroll;
    }

    @include media-breakpoint-up(xl) {
        background: none;
        overflow: hidden;
    }
}

body {
    &.side-menu-open {
        height: 100vh;
        overflow-y: hidden;
    }
}

@keyframes right-close-animate {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

@keyframes right-animate {
    0% {
        opacity: 0;
        transform: translateX(200%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
