@import './placeholders';

%-alert-common {
    @extend %font-kanit-normal;

    backdrop-filter: blur(10px);
    background-color: adjust-color($color: $jasmine, $alpha: -0.5);
    border-color: $jasmine;
    color: $black;
    font-size: rem-calc(16);
    letter-spacing: rem-calc(1.05);
    line-height: rem-calc(28);
    position: fixed;
    top: rem-calc(170);
    z-index: $zindex-tooltip;
}

%-close-button {
    background-image: url('../images/close-icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: rem-calc(12);
    opacity: 0.6;
    padding: 0;
    width: rem-calc(12);

    &:focus {
        outline: 0;
    }

    &:hover {
        opacity: 1;
    }

    span {
        display: none;
    }
}

.alert {
    @extend %-alert-common;

    border-radius: rem-calc(4);
    left: 50%;
    max-width: rem-calc(879);
    padding: rem-calc(15 81);
    text-align: center;
    text-transform: uppercase;
    transform: translateX(-50%);

    &.alert-warning {
        border-color: $jasmine;
    }
}

.alert-dismissible {
    .close {
        @extend %-close-button;

        right: rem-calc(15);
        top: 50%;
        transform: translateY(-50%);
    }
}

.toast {
    @extend %-alert-common;

    right: rem-calc(30);
}

.toast-header {
    background-color: adjust-color($color: $jasmine, $alpha: -0.15);
    color: $black;
    padding: rem-calc(15);

    .close {
        @extend %-close-button;
    }
}

.toast-body {
    padding: rem-calc(15);
}
