@charset "UTF-8";

//global
@import '../variables';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/mixins/grid';
@import '../settings/variables';
@import '../mixins';

// Components
@import '../components/placeholders';

// Product Components
@import './quantitySelector';

.add-to-cart-modal {
    .modal-dialog {
        margin: rem-calc(100) auto 0;
        max-width: rem-calc(846);

        @include media-breakpoint-down(xs) {
            bottom: 0;
            position: fixed;
            width: 100%;
        }
    }

    .modal-header {
        border: none;
        padding: 0;

        .close {
            font-size: rem-calc(20);
            margin: 0;
            opacity: 1;
            padding: 0;
            position: absolute;
            right: rem-calc(30);
            top: rem-calc(30);
        }
    }

    &__content {
        border-radius: rem-calc(10 10 0 0);
        padding: rem-calc(21) 0 0;

        @include media-breakpoint-up(sm) {
            border: 0;
            border-radius: 0;
            padding: rem-calc(60);
        }
    }

    .quantity-select {
        background: url('../../../experience/images/arrow-gold.png') no-repeat right 10px center/15px 15px;
        border: 1px solid $accent;
        font-family: $font-serif;
        font-size: rem-calc(16);
        font-weight: 400;
        line-height: rem-calc(20);
        min-height: rem-calc(48);
    }

    .product-image {
        height: rem-calc(300);

        img {
            height: 100%;
            object-fit: contain;
            object-position: top;
            width: 100%;
        }
    }

    .product-name {
        color: $woodsmoke;
        display: flex;
        flex-direction: column;
        font-family: $font-serif;
        font-size: rem-calc(24);
        font-weight: 400;
        letter-spacing: normal;
        line-height: rem-calc(29);
        margin-bottom: rem-calc(24);

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(32);
            line-height: rem-calc(37);
            margin-bottom: rem-calc(10);
        }
    }

    .product-subname {
        display: none;

        @include media-breakpoint-up(lg) {
            @include description($sm-line-height: 1.75, $multiple-size: false);

            display: block;
        }
    }

    .add-to-cart {
        letter-spacing: rem-calc(1);
        line-height: rem-calc(16);
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
            border-radius: rem-calc(0 3 3 0);
        }

        @include media-breakpoint-up(lg) {
            margin-top: rem-calc(18);
            padding-bottom: rem-calc(17);
            padding-top: rem-calc(17);
        }
    }

    .product-price {
        align-items: center;
        display: flex;
        flex-direction: column;

        > .price {
            > span {
                @include media-breakpoint-down(sm) {
                    align-items: center;
                    display: flex;

                    del {
                        display: flex;
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: rem-calc(13);
                padding-bottom: rem-calc(16);
                position: relative;

                &::after {
                    @include horizontal-center;

                    background: $accent;
                    bottom: 0;
                    content: '';
                    height: rem-calc(1);
                    width: rem-calc(61);
                }
            }
        }

        .price {
            .value {
                color: #202020;
                display: block;
                font-family: $font-sans-serif;
                font-size: rem-calc(16);
                font-weight: 400;
                letter-spacing: rem-calc(1.78);
                line-height: rem-calc(24);
                margin-bottom: 0;
                padding-bottom: 0;
                position: relative;

                @include media-breakpoint-up(lg) {
                    font-size: rem-calc(24);
                    letter-spacing: rem-calc(2);
                    line-height: rem-calc(36);
                    margin-right: rem-calc(12);
                    padding-right: rem-calc(12);

                    &::after {
                        @include vertical-center;

                        background: $accent;
                        content: '';
                        height: rem-calc(16);
                        left: auto;
                        right: 0;
                        width: rem-calc(1);
                    }
                }
            }
        }

        .strike-through {
            .value {
                @extend %font-kanit-normal;

                color: $regular-grey;
                text-align: left;
                text-decoration: line-through;

                &::after {
                    display: none;
                }

                @include media-breakpoint-up(lg) {
                    font-size: rem-calc(18);
                    line-height: 1.5;
                }
            }

            @include media-breakpoint-up(lg) {
                position: absolute;
                transform: translateY(-100%);
            }
        }

        .club-price {
            color: $black;
            font-family: $font-sans-serif;
            font-size: rem-calc(16);
            font-weight: 400;
            letter-spacing: rem-calc(1.78);
            line-height: rem-calc(24);
            margin-bottom: rem-calc(24);

            @include media-breakpoint-up(lg) {
                font-size: rem-calc(24);
                letter-spacing: rem-calc(2);
                line-height: rem-calc(36);
                margin-bottom: 0;
                padding-bottom: 0;
                padding-right: rem-calc(12);
            }
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    }

    .quantity-selector {
        height: rem-calc(52);

        &__btn {
            background: $accent;

            @include media-breakpoint-up(xl) {
                background: transparent;
            }
        }

        @include media-breakpoint-up(xl) {
            margin-right: rem-calc(18);
        }
    }
}
