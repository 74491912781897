.shipping-banner {
    background-color: $primary;
    padding: rem-calc(7 0 8);

    &--text {
        color: $light-white;
        font-family: $font-sans-serif;
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(20);
    }

    &--button {
        @include vertical-center;

        right: 0;

        .close {
            background: none;
            border: none;
        }
    }

    @include media-breakpoint-up(lg) {
        padding: rem-calc(12 0 11);
    }

    @include media-breakpoint-up(xl) {
        padding: rem-calc(7 0 8);
    }
}
