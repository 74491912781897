.footer {
    background-color: $primary;
    padding-bottom: 0;
    padding-top: rem-calc(58);

    &__col-logo {
        @include media-breakpoint-up(xl) {
            flex: 1 1;
            margin-bottom: rem-calc(45);
            max-width: rem-calc(515);
        }
    }

    &__logo {
        .typo-logo {
            margin-bottom: rem-calc(18);
            max-width: rem-calc(239);
            width: 100%;

            @include media-breakpoint-up(md) {
                margin-bottom: rem-calc(54);
            }
        }

        .grape-logo {
            margin-bottom: rem-calc(41);
            width: rem-calc(75);
        }

        @include media-breakpoint-down(sm) {
            align-items: center;
            display: flex;
            flex-direction: column;
        }
    }

    &__address {
        color: $light-white;
        font-family: $font-sans-serif;
        font-size: rem-calc(14);
        font-weight: 300;
        letter-spacing: normal;
        letter-spacing: rem-calc(1);
        line-height: 1.3;
        text-align: center;
        text-transform: uppercase;

        .time {
            display: inline-block;
            margin-top: rem-calc(22);
        }

        address {
            font: inherit;
            margin: 0;
        }

        @include media-breakpoint-up(md) {
            font-weight: 400;
            letter-spacing: normal;
            text-align: left;
        }

        @include media-breakpoint-up(xl) {
            display: inline-block;
            padding-left: rem-calc(20);
            text-align: center;
        }
    }

    &__navigation {
        margin: rem-calc(41 0 48);

        &--list {
            margin: 0;

            + .footer__navigation--list {
                @include media-breakpoint-down(lg) {
                    margin-top: rem-calc(29);
                }

                @include media-breakpoint-up(xl) {
                    padding-left: rem-calc(63);
                }
            }

            @include media-breakpoint-up(xl) {
                margin-top: rem-calc(-11);
            }
        }

        &--text {
            color: $light-white;
            font-family: $font-sans-serif;
            font-size: rem-calc(14);
            font-weight: 500;
            letter-spacing: rem-calc(2);
            line-height: rem-calc(40);

            &::after {
                content: none;
            }

            &:hover {
                color: $white;
            }

            @include media-breakpoint-up(md) {
                font-weight: 600;
                letter-spacing: rem-calc(1);
            }
        }

        .content-asset {
            @include media-breakpoint-up(xl) {
                display: flex;
            }
        }

        @include media-breakpoint-up(md) {
            margin: rem-calc(25 0 0);
        }

        @include media-breakpoint-up(xl) {
            margin: 0;
        }
    }

    &__darker-section {
        @include media-breakpoint-down(sm) {
            background: $dark-brown;
        }
    }

    &__subscribe {
        padding: rem-calc(54 45 34);
        text-align: center;

        h3 {
            color: $light-white;
            font-family: $font-serif;
            font-size: rem-calc(26);
            font-weight: 400;
            line-height: rem-calc(36);
            margin-bottom: rem-calc(15);

            @include media-breakpoint-up(md) {
                font-size: rem-calc(30);
                font-weight: 700;
                line-height: rem-calc(36);
                margin-bottom: rem-calc(24);
            }
        }

        p {
            color: $light-white;
            font-family: $font-sans-serif;
            font-size: rem-calc(16);
            font-weight: 300;
            letter-spacing: rem-calc(1);
            line-height: rem-calc(22);
            margin-bottom: 0;

            @include media-breakpoint-up(md) {
                font-family: $font-sans-serif;
                font-weight: 400;
                letter-spacing: normal;
                max-width: rem-calc(480);
            }
        }

        &--text {
            color: $light-white;
            font-family: $font-sans-serif;
            font-size: rem-calc(16);
            font-weight: 300;
            letter-spacing: rem-calc(1);
            line-height: 1.35;
            text-align: center;

            @include media-breakpoint-up(md) {
                font-weight: 400;
                letter-spacing: normal;
                text-align: left;
            }
        }

        @include media-breakpoint-up(md) {
            padding: 0 0 rem-calc(34);
            text-align: left;
        }
    }

    &__subscribe-form {
        @include media-breakpoint-down(sm) {
            padding: 0 rem-calc(20);
        }
    }

    &__form {
        margin-bottom: rem-calc(33);

        .input-group-append {
            border-bottom: 1px solid rgba(245, 239, 228, 0.2);
            margin-left: 0;

            @include media-breakpoint-up(lg) {
                border-bottom-width: rem-calc(2);
            }
        }

        &--input {
            &.form-control {
                background: none;
                border: 0;
                border-bottom: rem-calc(2) solid rgba($light-white2, 0.2);
                border-radius: 0;
                color: $white;
                font-family: $font-sans-serif;
                font-size: rem-calc(14);
                font-weight: 500;
                height: auto;
                letter-spacing: rem-calc(2);
                line-height: rem-calc(21);
                padding-bottom: rem-calc(13);
                text-align: center;
                text-transform: uppercase;

                &:focus {
                    border-color: rgba($light-white2, 0.2);
                    box-shadow: none;
                }

                &::placeholder {
                    color: rgba($white, 0.5);
                }

                @include media-breakpoint-up(md) {
                    font-weight: 600;
                    letter-spacing: rem-calc(1);
                    line-height: rem-calc(16);
                    padding: 0 0 rem-calc(24);
                    text-align: left;
                }
            }
        }

        &--submit {
            background: none;
            border: none;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(18);
        }
    }

    &__form-btn {
        margin: rem-calc(31) auto rem-calc(48);
        max-width: rem-calc(290);
        width: 100%;
    }

    &__social {
        margin-top: rem-calc(32);

        ul {
            display: flex;
            justify-content: center;
            list-style: none;
            margin: 0 rem-calc(-15);
            padding: 0;

            li {
                padding: 0 rem-calc(15);
            }

            a {
                color: $light-white2;
                display: block;
                font-size: rem-calc(24);
                line-height: 1;
                transition: color 0.3s ease-out;

                &:hover {
                    color: $white;
                }
            }

            @include media-breakpoint-up(md) {
                justify-content: flex-start;
            }
        }
    }

    &__copy {
        color: $light-white;
        font-family: $font-sans-serif;
        font-size: rem-calc(12);
        font-weight: 400;
        line-height: 2.33;
        padding-bottom: rem-calc(30);
        padding-top: rem-calc(75);
        text-align: center;

        @include media-breakpoint-down(sm) {
            background: $dark-brown;
            padding-left: rem-calc(20);
            padding-right: rem-calc(20);
        }

        @include media-breakpoint-up(md) {
            margin-top: rem-calc(70);
        }

        @include media-breakpoint-up(lg) {
            margin-top: rem-calc(105);
            text-align: left;
        }
    }

    @include media-breakpoint-up(md) {
        padding-bottom: rem-calc(48);
        padding-top: rem-calc(113);
    }

    @include media-breakpoint-up(xl) {
        padding-top: rem-calc(137);
    }
}

.cookie-warning-messaging {
    bottom: 0;
    display: none;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: $zindex-fixed;

    .alert {
        align-items: center;
        background: $offdark;
        border: 0;
        border-radius: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        max-width: none;
        padding: 0 rem-calc(30);
        position: static;
        transform: none;
        width: 100%;

        p {
            @include description($color: $offwhite, $multiple-size: false);

            margin: rem-calc(15) 0;
            text-transform: none;

            a {
                color: inherit;
                font: inherit;
                line-height: inherit;
                text-decoration: underline;

                &:hover {
                    color: $white;
                    text-decoration: underline;
                }
            }
        }

        .close {
            @include button-outline($offwhite, $square: true);

            height: auto;
            letter-spacing: rem-calc(1);
            line-height: 1.35;
            margin: rem-calc(15 0 15 15);
            opacity: 1;
            padding: rem-calc(8 20);
            position: static;
            transform: none;
            width: auto;

            &:hover {
                opacity: 1;
            }
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: 0;
        }
    }
}
