@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: './fonts/' !default;
$slick-font-family: 'slick' !default;
$slick-loader-path: './' !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    } @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    } @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url('../images/ajax-loader.gif') center center no-repeat;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    background: transparent;
    border: none;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 20px;
    line-height: 0px;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 20px;

    &:hover,
    &:focus {
        background: transparent;
        color: transparent;
        outline: none;

        &::before {
            opacity: $slick-opacity-on-hover;
        }
    }

    &.slick-disabled::before {
        opacity: $slick-opacity-not-active;
    }

    &::before {
        color: $slick-arrow-color;
        font-family: $slick-font-family;
        font-size: 20px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: 1;
        opacity: $slick-opacity-default;
    }
}

.slick-prev {
    left: -25px;

    [dir='rtl'] & {
        left: auto;
        right: -25px;
    }

    &::before {
        content: $slick-prev-character;

        [dir='rtl'] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: -25px;

    [dir='rtl'] & {
        left: -25px;
        right: auto;
    }

    &::before {
        content: $slick-next-character;

        [dir='rtl'] & {
            content: $slick-prev-character;
        }
    }
}

.slick-dots {
    bottom: -25px;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;

    li {
        cursor: pointer;
        display: inline-block;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        position: relative;
        width: 20px;

        button {
            background: transparent;
            border: 0;
            color: transparent;
            cursor: pointer;
            display: block;
            font-size: 0;
            height: 20px;
            line-height: 0px;
            outline: none;
            padding: 5px;
            width: 20px;

            &:hover,
            &:focus {
                outline: none;

                &::before {
                    opacity: $slick-opacity-on-hover;
                }
            }

            &::before {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        &.slick-active button::before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}
