@import 'site/product/productCard';

.minicart {
    margin: rem-calc(0 20 0 0);
    position: relative;

    .minicart-quantity {
        background-color: $accent;
        border-radius: 50%;
        bottom: rem-calc(13);
        color: $light;
        font-family: $font-sans-serif;
        font-size: 0;
        font-weight: 600;
        height: rem-calc(10);
        left: auto;
        letter-spacing: rem-calc(1);
        position: absolute;
        right: rem-calc(-4);
        text-align: center;
        top: auto;
        width: rem-calc(10);

        @include media-breakpoint-down(md) {
            align-items: center;
            display: flex;
            font-size: 14px;
            height: rem-calc(30);
            justify-content: center;
            left: rem-calc(18);
            letter-spacing: 0.0425rem;
            top: rem-calc(10);
            width: rem-calc(30);
        }

        @include media-breakpoint-between(md, lg) {
            align-items: center;
            display: flex;
            font-size: rem-calc(14);
            height: rem-calc(27);
            justify-content: center;
            left: rem-calc(30);
            line-height: rem-calc(27);
            top: rem-calc(15);
            width: rem-calc(26);
        }

        @include media-breakpoint-up(xl) {
            bottom: 0;
            font-size: rem-calc(14);
            height: rem-calc(27);
            line-height: rem-calc(27);
            right: rem-calc(-15);
            width: rem-calc(26);
        }

        @include media-breakpoint-up(xxl) {
            bottom: rem-calc(3);
        }
    }

    .minicart-link {
        color: $light-white3;
        display: block;
        position: relative;

        &:hover {
            color: $light-white3;
        }

        .fa {
            display: block;
            font-size: rem-calc(32);

            @include media-breakpoint-up(md) {
                font-size: rem-calc(40);
            }

            @include media-breakpoint-up(xxl) {
                font-size: rem-calc(47);
            }
        }

        .dark & {
            color: $primary;

            &:hover {
                color: $primary;
            }
        }
    }

    .popover {
        background: transparent;
        border: 0;
        border-radius: 0;
        display: none;
        left: auto;
        max-width: none;
        min-height: rem-calc(394);
        min-width: auto;
        padding-top: rem-calc(24);
        right: rem-calc(-57);
        top: rem-calc(36);
        width: rem-calc(397);

        &.show {
            @include media-breakpoint-up(xl) {
                display: block;

                &::before {
                    color: $white;
                    content: $fa-var-caret-up;
                    display: block;
                    font-family: $font-cakebread-icons;
                    font-size: rem-calc(24);
                    position: absolute;
                    right: rem-calc(65);
                    top: rem-calc(37);
                    transform: translateY(-100%);
                }
            }
        }
    }

    .btn-checkout {
        padding-bottom: rem-calc(19);
        padding-top: rem-calc(19);
    }

    &__container {
        background: $white;
        box-shadow: rem-calc(0 2 6) rgba($black, 0.25);
        padding: rem-calc(25 20 24);
    }

    &__header {
        align-items: center;
        border-bottom: rem-calc(1) solid $alto;
        display: flex;
        justify-content: space-between;
        margin-bottom: rem-calc(2);
        padding-bottom: rem-calc(18);
    }

    &__title {
        @include heading($sm-font-size: 16, $sm-line-height: 1.25, $multiple-size: false);

        margin-bottom: 0;
        text-transform: capitalize;
    }

    &__link {
        border: 0;
        font-size: rem-calc(16);
        font-weight: 300;
        letter-spacing: rem-calc(1);
        line-height: 1.25;
        padding: 0;
        text-decoration: underline;
    }

    &__product {
        border: 0;
        border-radius: 0;
        margin: 0;

        .card-body {
            border-bottom: rem-calc(1) solid $alto;
            padding: rem-calc(20) 0;
        }

        .minicart-line-item {
            position: relative;
        }

        .line-item-body {
            align-items: center;
            display: flex;
        }

        .line-item-footer {
            align-items: flex-start;
            display: flex;
            margin-top: rem-calc(24);
        }

        .line-item-body-info {
            padding-left: rem-calc(19);
        }

        .line-item-attrs {
            color: $woodsmoke;
            display: block;
            font-family: $font-sans-serif;
            font-size: rem-calc(13);
            font-weight: 300;
            letter-spacing: 0;
            line-height: rem-calc(19);
            margin: 0;
            padding-top: rem-calc(16);

            p {
                color: inherit;
                font: inherit;
                line-height: inherit;
                margin: 0;
            }
        }

        .remove-product {
            color: $regular-grey;
            display: flex;
            font-size: rem-calc(13);
            padding: rem-calc(10);
            position: absolute;
            right: rem-calc(-10);
            top: rem-calc(-10);

            &:hover {
                color: $primary;
            }
        }

        .line-item-name {
            @include heading($sm-font-size: 16, $sm-line-height: 1.25, $multiple-size: false);

            display: block;
            margin-bottom: 0;
            max-width: rem-calc(229);
        }

        .line-item-unit-price {
            flex: 0 0 32%;
        }

        .line-item-quantity {
            flex: 0 0 rem-calc(73);

            .quantity-form {
                display: block;
            }

            select {
                border-bottom: 0;
                min-width: auto;
                padding: 0;
                width: 100%;
            }

            .form-select-hollow {
                width: 61%;

                &::after {
                    content: $fa-var-caret-down;
                }
            }
        }

        .line-item-total-price {
            flex: 1 1;
            text-align: right;
        }

        .line-item-total-price,
        .line-item-pricing-info,
        .price,
        .quantity-value {
            @include description($sm-font-size: 16, $sm-line-height: 1.3, $color: $primary, $multiple-size: false);

            margin: 0;
        }

        .price {
            .starting,
            .range,
            .sales,
            .pricing {
                font-weight: 300;
            }

            .strike-through {
                color: $regular-grey;
                margin: 0;
            }
        }

        .bundle-items {
            @include description($sm-font-size: 14, $color: $primary, $multiple-size: false);

            letter-spacing: normal;
            margin-bottom: 0;

            h4 {
                color: inherit;
                font: inherit;
                line-height: inherit;
                margin: 0;
            }

            ul {
                list-style-position: inside;
                margin: 0;
                padding: 0;
            }
        }
    }

    &__total {
        @include description($sm-font-size: 16, $sm-line-height: 1.3, $color: $primary, $multiple-size: false);

        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        padding: rem-calc(15 0 26);
    }

    @include media-breakpoint-up(md) {
        margin-right: rem-calc(63);
    }

    @include media-breakpoint-up(xxl) {
        margin-right: rem-calc(58);
    }
}
