.recommended-products {
    margin-bottom: rem-calc(20);
    margin-top: rem-calc(150);

    &__intro {
        margin-bottom: rem-calc(-18);

        > span {
            @include eyebrow();
            @include alignText($xs: center, $lg: center);

            @include media-breakpoint-up(lg) {
                margin-bottom: rem-calc(23);
            }
        }

        > h1,
        > h2,
        > h3,
        > h4,
        > h5,
        > h6 {
            @include heading($sm-font-size: 26, $md-line-height: 1.25, $xxl-line-height: 1.25);
            @include alignText($xs: center, $lg: center);

            margin-bottom: 0;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: rem-calc(100);
        }
    }

    &__description {
        @include description($sm-line-height: 1.5, $md-font-size: 24, $md-line-height: 1.67);
        @include alignText($xs: center, $lg: center);

        margin-bottom: 0;
        margin-top: rem-calc(25);

        p {
            color: inherit;
            font: inherit;
            line-height: inherit;
            margin-bottom: 1.5em;
        }

        @include media-breakpoint-up(lg) {
            margin: rem-calc(10) auto 0;
            max-width: rem-calc(1060);
        }
    }

    &__btn {
        font-size: rem-calc(16);
        margin-top: rem-calc(64);
        padding-bottom: rem-calc(13);
        padding-top: rem-calc(13);

        @include media-breakpoint-up(sm) {
            margin-left: auto;
            margin-right: auto;
            max-width: rem-calc(247);
        }

        @include media-breakpoint-up(md) {
            margin-top: rem-calc(78);
        }
    }

    @include media-breakpoint-up(xl) {
        margin-top: rem-calc(183);
    }
}

.recommendations {
    .slick-arrow {
        top: 50%;
    }

    .slick-list {
        margin: rem-calc(0 -15);
        overflow: hidden;
        padding-bottom: rem-calc(84) !important;
        padding-top: rem-calc(84) !important;

        @include media-breakpoint-up(lg) {
            margin: rem-calc(0 -10);
            padding-bottom: rem-calc(128) !important;
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: rem-calc(181) !important;
            padding-top: rem-calc(181) !important;
        }
    }

    .slick-dots {
        bottom: 0;
        margin: 0;
    }

    .slick-slide {
        margin: rem-calc(0 15);

        @include media-breakpoint-up(lg) {
            margin: rem-calc(0 10);
        }
    }

    .arrow__container {
        &--prev {
            @include media-breakpoint-up(xl) {
                left: rem-calc(-100);
            }
        }

        &--next {
            @include media-breakpoint-up(xl) {
                left: auto;
                right: rem-calc(-100);
            }
        }
    }

    .product--fullbleed {
        .product-tile__name {
            align-items: flex-end;
            display: flex;
        }
    }
}
