.header-wrapper {
    background: transparent;
    margin-top: auto;
    position: absolute;
    width: 100%;
    z-index: 1;
    z-index: 2;

    &.bg {
        background-color: darken($primary, 5%);
    }
}

.header {
    padding-bottom: rem-calc(21);
    padding-top: rem-calc(30);

    &__logo {
        flex: 0 0;

        &-link {
            align-items: center;
            display: flex;
        }

        .typo-logo {
            height: rem-calc(40);

            path {
                .dark & {
                    fill: $darklogo;
                }
            }

            @include media-breakpoint-up(md) {
                height: rem-calc(40);
            }

            @include media-breakpoint-up(xxl) {
                height: rem-calc(57);
            }
        }
    }

    &__links {
        align-items: center;
        display: flex;
        flex: 1 1;
        justify-content: flex-end;

        .nomenu & {
            display: none;
        }

        @include media-breakpoint-up(xxl) {
            padding-top: rem-calc(7);
        }
    }

    &__menu {
        .toggle-menu {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            height: rem-calc(30);
            justify-content: center;
            width: rem-calc(33);

            @include media-breakpoint-up(md) {
                align-items: flex-end;
                height: rem-calc(34);
                width: auto;
            }

            @include media-breakpoint-up(xxl) {
                height: rem-calc(50);
            }
        }

        &-text {
            color: $light-white3;
            display: block;
            font-family: $font-sans-serif;
            font-size: rem-calc(14);
            font-weight: 600;
            letter-spacing: rem-calc(2);
            line-height: 1;
            position: relative;
            text-transform: uppercase;

            .dark & {
                color: $dark-black;
            }

            @include media-breakpoint-up(xxl) {
                font-size: rem-calc(16);
            }
        }

        &-bar {
            background-color: $light-white3;
            border-radius: rem-calc(2.5);
            display: block;
            height: rem-calc(2);
            margin-top: rem-calc(6);
            width: 100%;

            .dark & {
                background-color: $dark-black;
            }

            &:nth-child(2) {
                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                }

                @include media-breakpoint-up(md) {
                    width: 96%;
                }

                @include media-breakpoint-up(xxl) {
                    width: 87%;
                }
            }

            &:nth-child(3) {
                @include media-breakpoint-up(md) {
                    width: 50%;
                }

                @include media-breakpoint-up(xxl) {
                    width: 45%;
                }
            }

            &:nth-child(4) {
                width: 61%;
            }

            @include media-breakpoint-up(md) {
                margin-right: rem-calc(4);
                margin-top: rem-calc(7);
            }
        }
    }

    &__login {
        align-items: center;
        color: $light-white3;
        display: flex;
        margin-right: rem-calc(20);

        &:hover {
            color: $light-white3;
        }

        .fa {
            display: inline-block;
            font-size: rem-calc(30);

            @include media-breakpoint-up(md) {
                font-size: rem-calc(40);
                margin-right: rem-calc(11);
            }

            @include media-breakpoint-up(xxl) {
                font-size: rem-calc(50);
                margin-right: rem-calc(13);
            }
        }

        span {
            max-width: rem-calc(235);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @include media-breakpoint-up(xl) {
                max-width: rem-calc(400);
            }

            @include media-breakpoint-up(xxl) {
                max-width: rem-calc(500);
            }
        }

        .dark & {
            color: $primary;

            &:hover {
                color: $primary;
            }
        }

        @include media-breakpoint-up(md) {
            font-family: $font-sans-serif;
            font-size: rem-calc(14);
            font-weight: 600;
            letter-spacing: rem-calc(2);
            line-height: 1;
            margin-right: rem-calc(45);
            text-transform: uppercase;
        }

        @include media-breakpoint-up(lg) {
            margin-right: rem-calc(42);
        }

        @include media-breakpoint-up(xxl) {
            font-size: rem-calc(16);
            margin-right: rem-calc(58);
        }
    }

    &.solid {
        @include media-breakpoint-down(sm) {
            padding-top: rem-calc(21);
        }

        @include media-breakpoint-up(xl) {
            padding-top: rem-calc(31);
        }
    }

    @include media-breakpoint-down(sm) {
        padding-top: rem-calc(21);
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: rem-calc(40);
        padding-top: 0;

        &.no-sites {
            padding-top: rem-calc(40);
        }
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: rem-calc(33);

        &.no-sites {
            padding-top: rem-calc(33);
        }
    }

    @include media-breakpoint-up(xxl) {
        padding-bottom: rem-calc(31);

        &.no-sites {
            padding-top: rem-calc(59);
        }
    }
}

.header-row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-left: rem-calc(5);
    padding-right: rem-calc(5);

    @include media-breakpoint-up(md) {
        padding: 0;
    }
}

.header-banner {
    background-color: $primary;
    height: rem-calc(34);
    padding-bottom: rem-calc(7);
    padding-top: rem-calc(7);
    position: relative;
    z-index: 1;

    &__content {
        color: $light-white;
        font-family: $font-sans-serif;
        font-size: rem-calc(14);
        font-weight: 300;
        height: 100%;
        line-height: rem-calc(20);
        overflow: hidden;
        padding-right: rem-calc(30);
        position: relative;

        a {
            color: inherit;
            font: inherit;
            font-weight: bolder;
            text-decoration: underline;

            &:hover {
                color: $white;
                text-decoration: underline;
            }
        }
    }

    &__close {
        align-items: center;
        display: flex;
        height: rem-calc(8);
        justify-content: center;
        opacity: 1;
        position: absolute;
        right: rem-calc(15);
        top: 50%;
        transform: translateY(-50%);
        width: rem-calc(8);

        .fa {
            color: $light-white;
            font-size: rem-calc(8);
        }
    }

    @include media-breakpoint-up(lg) {
        height: rem-calc(41);
        padding-bottom: rem-calc(10.5);
        padding-top: rem-calc(10.5);
    }
}
