%box-with-shadow {
    border: 0;
    border-bottom: rem-calc(6) solid $secondary;
    border-radius: 0;
    box-shadow: 0 0 rem-calc(6) 0 $gray-box-shadow;
    padding: rem-calc(42) rem-calc(15);

    .card-header {
        border: 0;
        color: $regular-grey;
        font-family: $font-serif;
        font-size: rem-calc(30);
        letter-spacing: 0;
        line-height: rem-calc(37);
        margin-bottom: rem-calc(12);
        padding: 0;
    }

    .card-header-custom {
        color: inherit;
        font: inherit;
        line-height: inherit;
        margin: 0;
    }

    @include media-breakpoint-up(sm) {
        padding: rem-calc(42) rem-calc(42);
    }
}

%sg-card-text {
    color: $black;
    font-family: $font-sans-serif;
    font-size: rem-calc(16);
    font-weight: 400;
    letter-spacing: normal;
    line-height: rem-calc(24);
    text-transform: none;
}

%sg-custom-checkbox {
    padding-left: 0;

    label {
        line-height: rem-calc(22);
        margin: 0;
        padding-left: rem-calc(32);

        &::before {
            background: $white;
            border: rem-calc(1) solid $grey5;
            border-radius: 0;
            content: '';
            height: rem-calc(22);
            left: 0;
            top: rem-calc(1);
            width: rem-calc(22);
        }

        &::after {
            height: rem-calc(42);
            left: rem-calc(-10);
            top: rem-calc(-9);
            width: rem-calc(42);
        }
    }

    .custom-control-input {
        &:checked {
            ~ .custom-control-label {
                &::before {
                    background-color: $secondary;
                    border-color: $secondary;
                }

                &::after {
                    background-image: url('../images/check-icon.svg');
                }
            }
        }
    }
}

%sg-headline-h1 {
    color: $woodsmoke;
    font-family: $font-serif;
    font-size: rem-calc(34);
    font-weight: 400;
    letter-spacing: rem-calc(-1.07);
    line-height: rem-calc(46);
    text-align: center;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(50);
        line-height: rem-calc(63);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(64);
        line-height: rem-calc(74);
    }
}

%sg-headline-h2 {
    color: $woodsmoke;
    font-family: $font-sans-serif;
    font-size: rem-calc(18);
    font-weight: 300;
    letter-spacing: 0;
    line-height: rem-calc(24);

    @include media-breakpoint-up(md) {
        font-size: rem-calc(30);
        line-height: rem-calc(45);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(36);
        line-height: rem-calc(54);
    }
}

%sg-headline-h3 {
    color: $accent;
    font-family: $font-sans-serif;
    font-size: rem-calc(16);
    font-weight: 400;
    letter-spacing: rem-calc(2);
    line-height: rem-calc(24);
    text-transform: uppercase;

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(16);
        line-height: rem-calc(24);
    }
}

%sg-text {
    color: $silver-chalice;
    font-family: $font-sans-serif;
    font-size: rem-calc(14);
    font-weight: 300;
    letter-spacing: rem-calc(0.88);
    line-height: rem-calc(24);

    @include media-breakpoint-up(md) {
        font-size: rem-calc(18);
        letter-spacing: rem-calc(0.95);
        line-height: rem-calc(28);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(20);
        letter-spacing: rem-calc(1.17);
    }
}

%control-input-box {
    border: rem-calc(2) solid $alto;
    border-radius: rem-calc(6);
    cursor: pointer;
    margin-bottom: rem-calc(16);
    padding: rem-calc(20 30);
    transition: border 0.25s ease-in-out;

    &.active {
        border-color: $sundance;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        margin-right: rem-calc(32);
        min-width: rem-calc(235);
    }
}

%sg-border-bottom {
    border-bottom: rem-calc(1) solid $alto;
}

%font-baskerville {
    color: $black;
    font-family: $font-serif;
    font-weight: 400;
}

%font-kanit-light {
    font-family: $font-sans-serif;
    font-weight: 300;
}

%font-kanit-normal {
    font-family: $font-sans-serif;
    font-weight: 400;
}

%font-kanit-medium {
    font-family: $font-sans-serif;
    font-weight: 500;
}

%font-kanit-bold {
    font-family: $font-sans-serif;
    font-weight: 600;
}

%font-work-normal {
    font-family: $font-sans-serif;
    font-weight: 400;
}

%font-work-medium {
    font-family: $font-sans-serif;
    font-weight: 500;
}

%border-bottom {
    border-bottom: rem-calc(1) solid $alto;
}

%border-top {
    border-top: rem-calc(1) solid $alto;
}
