.modal-background {
    background-color: $black;
    display: none;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: fixed;
    top: 0;
    width: 100%;
}

input[placeholder] {
    text-overflow: ellipsis;
}

.hide-order-discount {
    display: none;
}

.hide-shipping-discount {
    display: none;
}

.order-discount {
    color: $success;
}

.shipping-discount {
    color: $success;
}

.error-messaging {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.error-hero {
    background-image: url('../images/storelocator.jpg');
    margin-bottom: 0.625em;
}

.error-message {
    margin-bottom: 3.125rem;
    margin-top: 3.125rem;
}

.error.continue-shopping {
    margin-bottom: 6.25em;
}

.error-unassigned-category {
    color: $red;
}

.skip {
    background: $white;
    left: 0;
    overflow: hidden;
    padding: 1em 1.5em;
    position: absolute;
    top: -4.2em;
    transition: all 0.2s ease-in-out;
}

a.skip:active,
a.skip:focus,
a.skip:hover {
    background: $white;
    height: auto;
    left: 0;
    top: 0;
    transition: all 0.2s ease-in-out;
    width: auto;
    z-index: 10000000;
}

.card-header-custom {
    font-size: 1.5rem;
    margin-bottom: 0;
}

.container-membership {
    @extend .container;

    max-width: none;
    padding-left: 0;
    padding-right: 0;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        // Common values
        @each $position in $positions {
            .position#{$infix}-#{$position} { position: $position !important; }
        }
    }
}

.container-md {
    @extend .container;

    .container {
        @include media-breakpoint-up(md) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
    }
}

.container-xl {
    @extend .container;

    max-width: none;
    padding-left: 0;
    padding-right: 0;

    .container {
        padding-left: 0;
        padding-right: 0;
    }
}

.container--1330 {
    @include media-breakpoint-up(xl) {
        max-width: rem-calc(1330);
    }
}

.glued {
    white-space: nowrap;
}

/* stylelint-disable */
.visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}
/* stylelint-enable */
