@import 'bootstrap/scss/mixins/grid';

.links-to-sites {
    border-bottom: rem-calc(1) solid rgba($white, 0.4);
    margin-bottom: rem-calc(25);
    padding: rem-calc(32 0 48);

    .dark & {
        border-color: rgba(color-yiq($white), 0.4);
    }

    .nomenu & {
        display: none;
    }

    &__link {
        display: flex;
        height: rem-calc(30);

        +.links-to-sites__link {
            border-left: rem-calc(1) solid rgba($white, 0.4);
            margin-left: rem-calc(30);
            padding-bottom: rem-calc(8);
            padding-left: rem-calc(21);
            padding-top: rem-calc(7);

            .dark & {
                border-color: rgba(color-yiq($white), 0.4);
            }

            &.bezel {
                padding-bottom: rem-calc(0);
                padding-top: rem-calc(0);
            }

            @include media-breakpoint-down(md) {
                margin-left: rem-calc(21);
                padding-bottom: rem-calc(3);
                padding-top: rem-calc(2);
            }
        }

        svg {
            height: 100%;
            max-width: 100%;

            path {
                fill: $offwhite;

                .dark & {
                    fill: color-yiq($offwhite);
                }
            }
        }

        img {
            height: 100%;
        }

        .mullan-logo {
            padding-bottom: rem-calc(1);
            padding-top: rem-calc(1);
        }

        @include media-breakpoint-up(lg) {
            height: rem-calc(45);
            padding-bottom: rem-calc(6);
            padding-top: rem-calc(4);
        }
    }

    .header-row {
        justify-content: center;

        @include media-breakpoint-up(lg) {
            justify-content: flex-start;
        }
    }

    @include media-breakpoint-down(md) {
        padding: rem-calc(0 0 12);
    }

    @include media-breakpoint-up(lg) {
        border-bottom: rem-calc(1) solid rgba($white, 0.4);
        padding: 0;
    }
}
