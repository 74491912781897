@font-face {
    font-display: block;
    font-family: '#{$font-cakebread-icons}';
    font-style: normal;
    font-weight: normal;
    src:
        url('../fonts/#{$font-cakebread-icons}.ttf?vsewax') format('truetype'),
        url('../fonts/#{$font-cakebread-icons}.woff?vsewax') format('woff'),
        url('../fonts/#{$font-cakebread-icons}.svg?vsewax##{$font-cakebread-icons}') format('svg');
}

// brand icon unicodes should match font-awesome v4.7
// icons that are not in font-awesome should be explicitly added
.brand-icon {
    font-family: '#{$font-cakebread-icons}' !important;
    speak: never;
    text-transform: none;
}

// override font-awesome icons
.fa {
    &-check,
    &-times,
    &-play-circle-o,
    &-info-circle,
    &-chevron-left,
    &-chevron-right,
    &-chevron-up,
    &-chevron-down,
    &-arrow-left,
    &-arrow-right,
    &-arrow-up,
    &-arrow-down,
    &-plus,
    &-shopping-cart,
    &-facebook,
    &-caret-left,
    &-caret-right,
    &-caret-up,
    &-caret-down,
    &-user-circle,
    &-window-close {
        @extend .brand-icon;
    }
}
