@import 'bootstrap/scss/variables';

#textSignUpPromoModal {
    .modal-dialog {
        align-items: center;
        display: flex;
        margin: auto;
        min-height: calc(100% - 1rem);
    }

    .modal-content {
        padding: rem-calc(5 20 5 20);
    }

    .modal-header {
        line-height: 1;
        margin: 0;
    }

    .cross-btn {
        color: $accent;
        display: block;
        font-size: rem-calc(60);
        width: fit-content;
    }

    .msg-container {
        text-align: center;
    }

    .eyebrow {
        @include eyebrow();

        margin-bottom: rem-calc(25);
    }

    .msg {
        color: $primary;
        font-family: $font-serif;
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.0625rem;
        line-height: 1.1875rem;
    }

    .joining-link {
        /*
        font-family: $font-serif;
        */
        color: $accent;
        font-weight: bold;
        text-decoration: underline;
    }
}
