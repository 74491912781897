$ns: 'product-tile';
$custom-gutter: 20;

.#{$ns} {
    box-shadow: 0 0 rem-calc(6) 0 $gray-box-shadow;
    cursor: pointer;
    position: relative;
    transition: margin 0.5s ease-in-out;

    &__inner {
        background-color: $white;
        padding: rem-calc(13 14 22);
        position: relative;
        z-index: 1;
    }

    &__picture {
        background: $offwhite;
        height: rem-calc(335);
        margin: 0 auto;
        position: relative;
        width: 100%;
    }

    &__link {
        color: inherit;
        display: block;
        font: inherit;
        height: 100%;
        letter-spacing: inherit;
        line-height: inherit;

        &::after {
            content: none;
        }
    }

    &__image {
        height: 100%;
        left: 50%;
        position: relative;
        transform: translateX(-50%);

        &-container {
            bottom: rem-calc(-80);
            height: rem-calc(486);
            pointer-events: none;
            position: absolute;
            width: 100%;

            .product--double & {
                bottom: rem-calc(50);
                height: rem-calc(310);

                @include media-breakpoint-up(sm) {
                    bottom: rem-calc(-36);
                    height: rem-calc(500);
                }

                @include media-breakpoint-up(lg) {
                    bottom: rem-calc(-103);
                    height: rem-calc(735);
                }
            }
        }

        &-shadow {
            bottom: rem-calc(18);
            display: block;
            filter: blur(8px);
            height: rem-calc(70);
            left: 50%;
            mix-blend-mode: normal;
            opacity: 0.5;
            position: absolute;
            width: rem-calc(123);
        }

        &-small-shadow {
            bottom: 0;
            display: none;
            filter: blur(6px);
            opacity: 0.9;
            position: absolute;
            right: rem-calc(-45);
            width: rem-calc(85);
            z-index: -1;
        }
    }

    &__bg {
        height: 100%;
        object-fit: cover;
        object-position: center top;
        opacity: 0;
        width: 100%;

        &-container {
            display: block;
            height: 100%;
            overflow: hidden;
            width: 100%;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        min-height: rem-calc(188);
        padding-top: rem-calc(80);
        position: relative;
        text-align: center;

        .product--double & {
            @include media-breakpoint-up(xl) {
                margin: 0 auto;
                max-width: rem-calc(350);
            }
        }
    }

    &__name {
        @include heading($sm-font-size: 20, $sm-line-height: 1.35, $multiple-size: false);

        flex: 1 1;
        margin-bottom: 0;
        transition:
            flex 0.5s ease-in-out,
            font 0.5s ease-in-out,
            line-height 0.5s ease-in-out;

        .animated & {
            flex: 0 0;
        }
    }

    &__price {
        color: $accent;
        font-family: $font-sans-serif;
        font-size: rem-calc(14);
        font-weight: 600;
        letter-spacing: rem-calc(2);
        line-height: 1.5;
        padding-top: rem-calc(8);
        transform: translateY(0);
        transition:
            font 0.5s ease-in-out,
            padding 0.5s ease-in-out;

        .price {
            color: inherit;
            display: inline;

            .sales {
                font-weight: 600;
            }
        }

        .club-price {
            text-transform: uppercase;
        }

        @include media-breakpoint-only(xs) {
            align-items: flex-end;
            display: flex;
            justify-content: center;

            > .price {
                padding-right: rem-calc(5);

                > span {
                    align-items: flex-start;
                    display: inline-flex;
                    flex-direction: column;
                }

                .strike-through {
                    margin-right: 0;
                }
            }
        }
    }

    &__description {
        @include description($sm-font-size: 14, $sm-line-height: 1.3, $multiple-size: false);

        font-weight: 400;
        left: 50%;
        letter-spacing: normal;
        margin-bottom: 0;
        opacity: 0;
        position: absolute;
        text-align: center;
        top: rem-calc(220);
        transform: translateX(-50%);
        width: calc(100% - 16px);

        p {
            color: inherit;
            font: inherit;
            line-height: inherit;
            margin: 0;
        }

        .product--double & {
            @include media-breakpoint-up(xl) {
                width: 100%;
            }
        }
    }

    &__action {
        align-items: center;
        background-color: $white;
        border-bottom: solid rem-calc(4) $accent;
        bottom: rem-calc(-4);
        display: flex;
        flex-direction: column;
        padding: rem-calc(3 22 32);
        position: absolute;
        width: 100%;
        z-index: 0;

        &-button {
            font-size: rem-calc(14);
            font-weight: 600;
            letter-spacing: rem-calc(2);
            line-height: rem-calc(16);
            padding-bottom: rem-calc(15);
            padding-top: rem-calc(15);
            width: 100%;
        }

        &-link {
            color: $primary;
            font-family: $font-sans-serif;
            font-size: rem-calc(14);
            font-weight: 600;
            letter-spacing: rem-calc(2);
            line-height: rem-calc(16);
            margin-top: rem-calc(16);
            text-transform: uppercase;
        }
    }
}

// Style Variations
.product {
    &--fullbleed {
        .#{$ns} {
            box-shadow: none;
            margin-bottom: 0;

            &__inner {
                background: transparent;
                box-shadow: none;
                display: flex;
                padding: 0;
            }

            &__picture {
                background: none;
                height: rem-calc(305);

                @include media-breakpoint-up(md) {
                    height: rem-calc(343);
                }

                @include media-breakpoint-up(lg) {
                    height: rem-calc(415);
                }

                @include media-breakpoint-up(xxl) {
                    height: rem-calc(520);
                }
            }

            &__bg {
                opacity: 1;
            }

            &__image {
                &-container {
                    bottom: rem-calc(-74);
                    height: rem-calc(400);

                    @include media-breakpoint-up(md) {
                        bottom: rem-calc(-84);
                        height: rem-calc(452);
                    }

                    @include media-breakpoint-up(lg) {
                        bottom: rem-calc(-129);
                        height: rem-calc(581);
                    }

                    @include media-breakpoint-up(xxl) {
                        bottom: rem-calc(-138);
                        height: rem-calc(694);
                    }
                }

                &-shadow {
                    bottom: rem-calc(9);
                    height: rem-calc(64);
                    width: rem-calc(115);

                    @include media-breakpoint-up(lg) {
                        bottom: rem-calc(18);
                        height: rem-calc(89);
                        width: rem-calc(155);
                    }

                    @include media-breakpoint-up(xxl) {
                        bottom: rem-calc(9);
                        height: rem-calc(113);
                        width: rem-calc(197);
                    }
                }

                &-small-shadow {
                    display: none;
                }
            }

            &__body {
                bottom: 0;
                justify-content: flex-start;
                min-height: rem-calc(143);
                padding-top: rem-calc(15);
                position: absolute;
                right: rem-calc(5);
                text-align: left;
                transform: rotate(-180deg);
                writing-mode: vertical-rl;

                @include media-breakpoint-up(lg) {
                    flex: 0 0 rem-calc(36);
                    flex-direction: column;
                    height: rem-calc(415);
                    padding-bottom: rem-calc(20);
                    padding-top: 0;
                    position: relative;
                    right: rem-calc(-7);
                }

                @include media-breakpoint-up(xxl) {
                    flex-basis: rem-calc(48);
                    height: rem-calc(520);
                }
            }

            &__name {
                color: $white;
                font-family: $font-sans-serif;
                font-size: rem-calc(14);
                font-weight: 600;
                letter-spacing: rem-calc(2);
                line-height: rem-calc(18);
                margin: 0;
                text-transform: uppercase;
                width: 100%;

                @include media-breakpoint-up(lg) {
                    color: $accent;
                    flex: 0 0;
                }

                @include media-breakpoint-up(xxl) {
                    font-size: rem-calc(16);
                    line-height: rem-calc(24);
                }
            }

            &__price,
            &__description,
            &__action {
                display: none;
            }

            &.animated {
                .#{$ns} {
                    &__name {
                        flex: 0 0;
                    }
                }
            }
        }
    }

    &--small {
        .#{$ns} {
            margin-bottom: rem-calc(56);

            &__inner {
                border-bottom: solid rem-calc(6) $secondary;
                padding: rem-calc(26 17 23);
            }

            &__picture {
                height: rem-calc(217);
            }

            &__image-container {
                bottom: rem-calc(-8);
                height: rem-calc(273);
            }

            &__image {
                &-shadow {
                    display: none;
                }

                &-small-shadow {
                    display: block;
                }
            }

            &__body {
                justify-content: flex-start;
                min-height: auto;
            }

            &__name {
                font-size: rem-calc(18);
                line-height: rem-calc(22);
                margin: rem-calc(22 0 10);
            }

            &__price {
                font-size: rem-calc(12);
                letter-spacing: rem-calc(1.5);
                line-height: rem-calc(18);
                margin-bottom: rem-calc(20);

                del {
                    display: none;
                }
            }

            &__action-button {
                font-weight: 400;
                letter-spacing: rem-calc(2);
                margin: 0 auto;
            }
        }
    }

    &--full-picture {
        .#{$ns} {
            &__inner {
                padding: rem-calc(13 13 25);
            }

            &__bg-container {
                display: none;
            }

            &__picture {
                height: rem-calc(322);
                overflow: hidden;
            }

            &__image {
                height: 100%;
                left: auto;
                object-fit: cover;
                position: static;
                transform: none;
                width: 100%;

                &-container {
                    bottom: auto;
                    height: 100%;
                    left: auto;
                    position: static;
                    transform: none;
                    width: 100%;
                }

                &-shadow {
                    display: none;
                }
            }

            &__body {
                min-height: rem-calc(108);
                padding-top: rem-calc(25);
            }

            &.animated {
                .#{$ns}__name {
                    flex: 1 1;
                }
            }
        }
    }

    &--special-tile {
        .#{$ns} {
            cursor: default;

            &__bg {
                opacity: 1;
            }

            &__image {
                &-container {
                    transform: scale(0.75) translateY(-30px);
                }
            }

            &__body {
                display: block;
                min-height: auto;
                padding-top: rem-calc(16);

                @include media-breakpoint-up(md) {
                    min-height: rem-calc(247);
                }
            }

            &__name {
                @include media-breakpoint-up(md) {
                    min-height: rem-calc(54);
                }
            }

            &__description {
                opacity: 1;
                padding: rem-calc(16);
                position: static;
                transform: none;
            }

            &__action {
                position: static;
            }

            a {
                cursor: default;
                pointer-events: none;
            }
        }
    }
}

.product-grid {
    padding-top: rem-calc(60);

    &__item {
        &.normal {
            margin-bottom: rem-calc(82);
            margin-top: rem-calc(100);
        }

        &.full-picture {
            margin-bottom: rem-calc(56);
        }

        &.special-tile {
            margin-bottom: rem-calc(64);
        }

        @include media-breakpoint-up(lg) {
            padding-left: rem-calc($custom-gutter / 2);
            padding-right: rem-calc($custom-gutter / 2);
        }
    }

    .product--double {
        .product-tile {
            @include media-breakpoint-up(xl) {
                width: rem-calc(792);
            }
        }
    }

    + .pagination {
        margin-bottom: rem-calc(240);
        position: relative;
        z-index: 10;
    }

    @include media-breakpoint-up(lg) {
        margin-left: rem-calc(-$custom-gutter / 2);
        margin-right: rem-calc(-$custom-gutter / 2);
    }
}
