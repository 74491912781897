#majorityConfirmationModal {
    .modal-dialog {
        max-width: rem-calc(789);

        @include media-breakpoint-up(lg) {
            margin-top: rem-calc(213);
        }
    }

    .modal-content {
        padding: rem-calc(50 30);

        @include media-breakpoint-up(lg) {
            padding: rem-calc(53 30 61);
        }
    }

    .modal-header {
        align-items: center;
        flex-direction: column;
        margin: 0;
        text-align: center;
    }

    .modal-footer {
        margin: 0;
    }

    .modal-actions {
        .btn {
            font-size: rem-calc(14);
            font-weight: 500;
            padding-bottom: rem-calc(13);
            padding-top: rem-calc(13);

            @include media-breakpoint-up(sm) {
                max-width: rem-calc(258);
            }
        }
    }

    .eyebrow {
        @include eyebrow();

        margin-bottom: rem-calc(25);
    }

    .description {
        @include heading($sm-font-size: 30, $sm-line-height: 1.5, $multiple-size: false);

        margin-bottom: rem-calc(30);

        @include media-breakpoint-up(sm) {
            line-height: 2.67;
            margin-bottom: rem-calc(11);
        }
    }

    .typo-logo {
        display: block;
        height: rem-calc(80);
        margin-bottom: rem-calc(15);

        path {
            fill: $primary;
        }
    }

    .grape-logo {
        height: rem-calc(80);
        margin-bottom: rem-calc(17);
    }
}
