@charset "UTF-8";

//global
@import './variables';
@import './bootstrap-custom-import';
@import '~base/bootstrapOverrides';
@import '~base/utilities/responsiveUtils';
@import 'font-awesome/scss/font-awesome';
@import '../fonts/brand-icons';
@import '~base/components/header';
@import '~base/components/footer';
@import '~base/components/hero';
@import '~base/components/notification';
@import '~base/components/pricing';
@import '~base/components/tabs';
@import '~base/components/spinner';
@import '~base/components/tooltip';
@import '~base/components/headerSearch';
@import './settings/include';

// Components
@import './components/alerts';
@import './components/buttons';
@import './components/common';
@import './components/footer';
@import './components/forms';
@import './components/header';
@import './components/linksToSites';
@import './components/modal';
@import './components/majorityModal';
@import './components/productTiles';
@import './components/shipping-banner';
@import './components/side-menu';
@import './components/slick';
@import './components/slick-theme';
@import './components/socialLinks';
@import './components/type';
@import './components/joiningModal';
@import './components/textSignUpPromo';

// Custom
@import './miniCart';
@import './product/addToCart';
@import './product/recommendations';
