.slick-dotted {
    .slick-dots {
        position: relative;

        button {
            &::before {
                border: 1px solid $accent;
                border-radius: 100%;
                content: '';
                height: rem-calc(15);
                width: rem-calc(15);
            }
        }

        .slick-active {
            button {
                &::before {
                    background: $accent;
                }
            }
        }
    }
}

.lh-0 {
    line-height: 0;
}

.logo-home {
    &::after {
        content: none;
    }
}
