.modal-dialog {
    margin: 0 auto;
    max-width: rem-calc(929);

    @include media-breakpoint-up(lg) {
        margin: rem-calc(189) auto rem-calc(100);
    }
}

.modal-content {
    background-color: $offwhite;
    border: 0;
    border-radius: 0;
    padding: rem-calc(50 32);

    @include media-breakpoint-up(lg) {
        padding: rem-calc(80 75 108);
    }
}

.modal-header {
    @include heading($multiple-size: false);

    background: transparent;
    border: 0;
    margin-bottom: rem-calc(37);
    padding: 0;

    > * {
        color: inherit;
        font: inherit;
        line-height: inherit;
        margin: 0;
        padding: 0;
    }

    .close {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        line-height: 2.18;
    }
}

.modal-body {
    @include description($sm-font-size: 18, $sm-line-height: 1.5, $md-font-size: 24, $md-line-height: 1.5);

    margin-bottom: 0;
    padding: 0;

    p,
    span {
        color: inherit;
        font: inherit;
        line-height: inherit;
        margin-bottom: rem-calc(34);
    }

    strong {
        color: $primary;
    }

    .fa {
        font-size: rem-calc(14);
        line-height: 2.25;
        margin-right: rem-calc(8);

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(16);
            margin-right: rem-calc(16);
        }
    }
}

.modal-footer {
    border: 0;
    justify-content: flex-start;
    margin-top: rem-calc(30);
    padding: 0;

    > * {
        margin: 0;
    }

    @include media-breakpoint-up(sm) {
        justify-content: space-between;
    }
}

.modal-actions {
    align-items: center;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    margin: rem-calc(-8) 0;

    .btn {
        margin: rem-calc(8) 0;
        width: 100%;

        @include media-breakpoint-up(sm) {
            flex: 1 1 50%;
            margin: 0 rem-calc(10);
            max-width: 50%;
        }

        @include media-breakpoint-up(lg) {
            border-radius: rem-calc(100);
            border-width: rem-calc(2);
            font-size: rem-calc(18);
            font-weight: 700;
            margin: 0 rem-calc(20);
            padding-bottom: rem-calc(20);
            padding-top: rem-calc(20);
        }
    }

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;
        margin: 0 rem-calc(-10);
    }

    @include media-breakpoint-up(lg) {
        margin: 0 rem-calc(-20);
    }
}
