.form-control {
    @include form-control();
}

textarea.form-control {
    padding-bottom: rem-calc(12);
    padding-top: rem-calc(12);
}

.form-control-label {
    @include form-control-label();

    + .info-icon {
        display: inline;
        margin-left: rem-calc(3);
        top: rem-calc(3);

        .fa {
            color: $accent;
            font-size: rem-calc(16);
        }
    }

    > a {
        font: inherit;
    }
}

.form-select {
    @include form-select();
}

.form-select-hollow {
    @include form-select($hollow: true);
}

.form-group {
    margin-bottom: rem-calc(20);

    &.required {
        .form-control-label::before {
            display: none;
        }
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(35);
    }
}

.form-check {
    @include form-check();

    + .form-check {
        margin-top: rem-calc(20);
    }
}

.form-check-square {
    @include form-check($round: false);
}

.form-custom-radio {
    border: rem-calc(2) solid rgba($primary, 0.12);
    border-radius: rem-calc(6);
    cursor: pointer;
    padding: rem-calc(21 30);
    transition: border 0.25s ease-in-out;
    width: 100%;

    &.active,
    &.checked {
        border-color: $accent;
    }

    .custom-control-label {
        color: $primary;
        cursor: pointer;
        font-family: $font-sans-serif;
        font-size: rem-calc(16);
        font-weight: 500;
        line-height: rem-calc(24);
        padding-left: rem-calc(33);

        &::before {
            background: $white;
            border: rem-calc(1) solid $primary;
            height: rem-calc(20);
            left: 0;
            top: rem-calc(2);
            transition: border 0.25s ease-in-out 0.1s;
            width: rem-calc(20);
        }

        &::after {
            background: none;
            color: $white;
            content: $fa-var-check;
            display: inline-block;
            font-family: $font-cakebread-icons;
            font-size: rem-calc(12);
            left: rem-calc(4);
            top: rem-calc(1);
            transform: scale(0);
            transition: transform 0.5s ease-in-out;
        }

        p {
            color: inherit;
            font: inherit;
            line-height: inherit;
            margin-bottom: 0;
        }
    }

    .custom-control-input {
        position: absolute;

        &:checked {
            ~ .custom-control-label {
                &::before {
                    background: $white;
                    border-color: $accent;
                    border-width: rem-calc(10);
                }

                &::after {
                    background: none;
                    transform: scale(1);
                }
            }

            &:focus,
            &:active {
                ~ .custom-control-label::before {
                    border-color: $accent;
                }
            }
        }

        &:focus,
        &:active {
            ~ .custom-control-label::before {
                background: $white;
                border-color: $accent;
                box-shadow: 0 0 0 rem-calc(3) rgba($accent, 0.25);
            }
        }
    }

    .custom-radio-description {
        color: $secondary;
        display: inline-block;
        font-family: $font-sans-serif;
        font-size: rem-calc(14);
        font-weight: 400;
        letter-spacing: rem-calc(1);
        line-height: rem-calc(24);
        margin-top: rem-calc(20);

        @include media-breakpoint-up(xl) {
            margin-top: rem-calc(4);
            padding-left: rem-calc(33);
        }
    }

    .custom-radio-more {
        margin-top: rem-calc(20);

        @include media-breakpoint-up(xl) {
            padding-left: rem-calc(33);
        }
    }
}
