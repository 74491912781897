%-qty-font {
    color: $primary;
    font-family: $font-serif;
    font-size: rem-calc(26);
    font-weight: 400;
    line-height: 1.25;
    text-align: center;

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(32);
    }
}

.quantity-selector {
    align-items: center;
    border: rem-calc(1) solid $accent;
    display: flex;
    height: rem-calc(50);
    justify-content: space-between;
    min-width: rem-calc(110);
    position: relative;
    z-index: 1;

    &__input {
        @extend %-qty-font;

        appearance: textfield;
        border: none;
        flex: 1 1 auto;
        height: 100%;
        max-width: 100%;
        min-height: auto;
        padding: 0 rem-calc(28);

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
        }
    }

    &__btn {
        @extend %-qty-font;

        background: transparent;
        border: none;
        border-radius: 0;
        height: 100%;
        padding: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: rem-calc(40);

        &--minus {
            left: 0;
        }

        &--plus {
            right: 0;
        }

        &:focus {
            outline: 0;
        }
    }
}
