%-product-text {
    color: $woodsmoke;
    display: block;
    font-family: $font-sans-serif;
    font-size: rem-calc(16);
    font-weight: 300;
    letter-spacing: 0;
    line-height: rem-calc(24);
    margin: 0;
    padding: 0;

    p {
        color: inherit;
        font: inherit;
        line-height: inherit;
        margin: 0;
    }
}

.product {
    &__card {
        border-top: rem-calc(1) solid $alto;
        padding: rem-calc(46) 0;
    }

    &__img {
        background-color: $beige;
        flex: 0 0 rem-calc(79);
        height: rem-calc(97);
        position: relative;
        width: rem-calc(79);

        &-bg {
            background-image: url('../images/elements/product-shadow.png');
            background-repeat: no-repeat;
            background-size: cover;
            height: 130%;
            left: 80%;
            position: absolute;
            top: -25px;
            transform: translateX(-50%) scale(-1, 1);
            width: rem-calc(36);
        }
    }

    &__picture {
        left: 50%;
        max-height: rem-calc(134);
        max-width: rem-calc(53);
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }

    &__info {
        padding-left: rem-calc(20);

        .line-item-name {
            color: $woodsmoke;
            font-family: $font-serif;
            font-size: rem-calc(16);
            font-weight: 400;
            letter-spacing: 0;
            line-height: rem-calc(20);
            margin: rem-calc(5 0 10);
        }

        .price {
            color: $woodsmoke;
            font-family: $font-sans-serif;
            font-size: rem-calc(16);
            font-weight: 300;
            line-height: rem-calc(24);

            .starting,
            .range,
            .sales,
            .pricing {
                font-weight: 300;
            }

            .strike-through {
                color: $regular-grey;
                margin-right: rem-calc(18);
            }
        }

        .line-item-quantity {
            .line-item-pricing-info {
                display: flex;
            }

            .qty-card-quantity-count {
                margin-left: rem-calc(4);
            }
        }

        .line-item-total-price {
            .strike-through {
                display: none;
            }
        }
    }

    &__text {
        @extend %-product-text;
    }

    &__bundle-items {
        @include description($color: $primary, $multiple-size: false);

        letter-spacing: normal;

        h4 {
            color: inherit;
            font: inherit;
            line-height: inherit;
            margin: 0;
        }

        ul {
            list-style-position: inside;
            margin: 0;
            padding: 0;
        }
    }
}

.line-item-promo {
    @extend %-product-text;

    color: $accent;
    font-size: rem-calc(13);
    line-height: rem-calc(19);
    margin: rem-calc(10) 0;
}
